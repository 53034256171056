.container {
  margin-right: -0.7em;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .close {
      margin-right: -1em;
    }

    h4 {
      margin: 0.5em 0;
    }
  }

  .dropfiles {
    background: #eeeeee !important;
    border-color: #aaaaaa !important;
    height: 350px;
    margin-right: 0 !important;
    max-width: 100%;

    svg {
      // display: none;
    }

    span {
      font-size: 18px;
      text-align: center;
      width: 100%;
      color: #000000;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    span:nth-child(2) {
      background: red;
      display: none;
    }
  }
}
