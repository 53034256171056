.shadow {
  box-shadow: 0 0 20px #ccc;
}

.container {
  margin-bottom: 1em;
}

.imgStyle {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
.commentBox {
  width: 60%;
}

// .imgStylePlus {
//   transition: all 0.3s;
//   transform: scale(1.2);
// }

.imgStyleCenter {
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
}

.imgStylePlus {
  transition: transform 0.5s;
  transform: scale(2.5);
}
.imgStyleMinus {
  transition: transform 0.5s;
  transform: scale(1);
}

.fakeFullScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// flipbook bottom section
.flipbookBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;

  .currentRenderedPage {
    border: 1.8px solid #d3d3d3;
    border-radius: 0.3em;
    padding: 0.3em;
    margin-left: 0.5em;
    margin-right: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center;

    .input {
      border: 0;
      width: 20px;
      margin-right: 0.3em;
      font-size: 16px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input:focus {
      outline: none;
    }
  }

  .pagination {
    margin-left: 1em;
  }

  .zoom {
    // margin-left: 1em;
  }
}

.discoveryComment {
  width: 60%;
}

.discoveryCommentReply {
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .imgStyle {
    max-height: 860px !important;
  }
}

@media only screen and (min-width: 120px) and (max-width: 768px) {
  .currentRenderedPage {
    width: 60px !important;
  }
  .discoveryComment {
    width: 100%;
  }
  .commentBox {
    width: 100%;
  }

  .currentRenderedPage {
    margin-left: 0.2rem !important;
  }

  .pagination {
    margin-left: 0.5em !important;
    button {
      padding: 8px;
      font-size: 11px;
    }
  }
}

@media only screen and (min-width: 120px) and (max-width: 560px) {
  .discoveryComment {
    width: 100%;
  }
  .commentBox {
    width: 100%;
  }
}
