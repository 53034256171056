.inputContaier {
  padding: 15px;
  h3 {
    font-weight: 500;
    font-size: 25px;
    margin: 0 auto 0.5em auto;
  }
  .text-center {
    text-align: center;
  }
  a {
    text-align: center;
  }
  .divider {
    margin-bottom: -3px;
  }
}

.googleButton {
  border: 1px solid !important;
  border-color: green !important;
  color: green !important;
  height: 50px;
  &:hover {
    border-color: rgb(11, 80, 11);
    color: rgb(11, 80, 11);
  }
  svg {
    font-size: 27px !important;
    outline: 0cap;
  }
}

.facebookButton {
  border: 1px solid #dadce0 !important;
  color: blue !important;
  border-radius: 4px;
  margin-top: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0 12px;
  &:hover {
    border-color: rgb(11, 80, 11);
    color: rgb(11, 80, 11);
    cursor: pointer;
  }
  svg {
    font-size: 27px !important;
    margin-right: 10px;
  }

  width: auto;
  text-align: center;
  vertical-align: top;
}

.linkButton {
  text-decoration: none;
  color: #1976d2;
  cursor: pointer;
}

.facebookButtonLink {
  background-color: #4267b2;
  color: #fff;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
}

.socialBox {
  display: flex;
  justify-content: space-between;     
}


@media (min-width: 320px) and (max-width: 420px) {
  .socialBox {
    display: grid;
    justify-content: center;
    align-items: center;  
  }
  .gooogleButtonLink {
    font-size: 12px !important;
    margin-bottom: 10px; 
  }
  .facebookButtonLink {
    padding: 12px 12px;
  }
}    
