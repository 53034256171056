body {
  margin: 0;
  // font-family: 'Chalkboard' !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiInput-root {
  width: 100%;
  margin-top: 8px !important;
}

.MuiTextField-root {
  margin-top: 8px !important;
}

.MuiInput-input {
  font: inherit !important;
  letter-spacing: inherit !important;
  box-sizing: content-box !important;
  background: none !important;
  height: 48px !important;
  display: block !important;
  min-width: 0 !important;
  width: 100% !important;
  -webkit-animation-duration: 10ms !important;
  animation-duration: 10ms !important;
  padding: 0 7px !important;
  border-radius: 5px !important;
  border: 2px solid lightgray !important;
}

.MuiSelect-standard {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}

.MuiGrid-root {
  .MuiGrid-container {
    margin-top: 50px;
  }
  .MuiTabs-fixed {
    margin-top: 50px;
  }
}

.main-bar {
  padding-left: 245px !important;
}

.sidebarLink {
  text-decoration: none;
  color: #1976d2;
  &.active {
    background-color: #e0ecfd !important;
    display: inherit;
    border-left: 3px solid;
    border-color: #1976d2;
    color: #0055a9;
  }
}

.sidebarParent {
  color: rgba(0, 0, 0, 0.87);
}

.channel-tabs .MuiTabs-fixed {
  margin-top: 15px;
}

.discovery-tab .MuiGrid-container {
  margin-top: 0px;
}

.discovery-tab .MuiBox-root {
  padding: 3px;
}

.discovery-tab-channel {
  .css-zz2iph {
    padding: 0;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12 {
    margin-top: 0;
  }
}

.rearrange {
  padding-left: 24px;
}

@media only screen and (min-width: 120px) and (max-width: 768px) {
  .discover_discoverSortContainerPopup__8u75r .MuiMenu-paper {
    width: 100%;
  }

  .rearrange {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 120px) and (max-width: 560px) {
  .discover_discoverCommentSort__XavzR .MuiMenu-paper {
    width: 100%;
  }
}

