.actionBtn {
  opacity: 0;
}

.actionBtn:hover {
  opacity: 1;
}

.projectTitleImage {
  min-width: 65px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  overflow: hidden;
  width: 65px;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
