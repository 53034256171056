.container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 41px;
}

.categoryInput {
  // min-width: 195px !important;
}

.containerMedia {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .containerMedia {
    display: inline;
  }
  .categoryInput {
    // min-width: 50px !important;
    max-width: 0;
    height: 35px;
  }
}