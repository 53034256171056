.discoverContainer {
  padding: 0 1em 1.5em 1em;
  cursor: pointer;

  h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 0.5em 0 0.3em 0;
  }

  .subContainer {
    display: flex;
    align-items: center;

    p {
      margin: 0.4em 1em 0.5em 0em;
      font-size: 14px;
    }

    span {
      font-size: 14px;
    }
  }

  .channel {
    margin: 0.2em 0 0.5em 0;
    font-size: 14px;
  }

  position: relative;

  .discoverImages {
    border: 1px solid #f1f1f1;
    // width: 250px;
    height: 150px;
    display: flex;
    align-items: center;
    background: #f1f1f1;
    position: relative;

    .imageContainer {
      width: 50%;
      height: 150px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    .gradientOverlay {
      position: absolute;
      top: 0;
      width: 10px;
      height: 100%;
      z-index: 1;
      /* Set a higher z-index than the image to overlay the gradients */
      pointer-events: none;
      /* Allow click events to pass through the gradient overlays */
    }

    .gradientLeft {
      left: calc(50% - 10px);
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.3),
        transparent
      );
      background-position: center;
    }

    .gradientRight {
      right: calc(50% - 10px);
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.3),
        transparent
      );
      background-position: center;
    }
  }
}

.discoveryFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.discoverySortBy {
  width: auto;
}

.projectUserInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1650px) {
  .discoverContainer {
    flex: 0 0 calc(16.66% - 2em);
  }
}

@media ((min-width: 1024px) and (max-width: 1649px)) {
  .discoverContainer {
    flex: 0 0 calc(25% - 2em);
  }
}

@media ((min-width: 769px) and (max-width: 1023px)) {
  .discoverContainer {
    flex: 0 0 calc(33.33% - 2em);
  }
}

@media only screen and (min-width: 421px) and (max-width: 768px) {
  .discoverContainer {
    flex: 0 0 calc(50% - 2em);
  }
  .discoveryFilters {
    display: block;
    width: 100%;
  }
  .discoverySortBy {
    width: 100%;
  }
  .discoverSortContainer {
    width: 100%;
    margin-top: 10px;
    button {
      width: 100%;
    }
  }

  .discoverSortContainerPopup{ 
    width: 100% !important;
  }
}

@media only screen and (min-width: 120px) and (max-width: 560px) {
  .discoverCommentSort {
    width: 100%;
  }
  .projectUserInfo {
    display: block;
  }
  .sortByDropdown {
    width: 100% ;
    button {
      width: 100%;
    }
  }
  .projectUserInfo {
    .subscribeButton {
      width: 100%;
      margin-top: 10px;
    }
  }
  .discoveryComment {
    width: 100% !important;
  }
 }

 @media only screen and (min-width: 120px) and (max-width: 420px) {
  .discoverContainer {
    flex: 0 0 calc(100% - 2em);
  }
  .discoveryFilters {
    display: block;
    width: 100%;
  }
  .discoverSortContainer {
    width: 100%;
    margin-top: 10px;
    button {
      width: 100%;
    }
  }
}
