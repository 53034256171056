.inputContainer {
  width: 100%;
  .label {
    font-size: 15px;
    display: block;
  }
}

.selectContainer {
  width: 97.2%;
}
